import {useFormatCurrency} from '@/utils/string/formatCurrency';
import {useTranslations} from '@/hooks/useTranslations';

import {RevenueValues} from '../constants';

export function useRevenueRanges() {
  const {t, site} = useTranslations();

  const formatCurrency = useFormatCurrency();
  const currencyFormat = (value: number) =>
    formatCurrency(value, {minimumSignificantDigits: 1});

  switch (site.locale) {
    // Japan has special currency overrides.
    case 'ja':
      return [
        {
          label: t('forms:lead.revenueRanges.options.DEFAULT'),
          value: '',
        },
        {
          label: '新規サイト立上げ',
          value: RevenueValues.GETTING_STARTED,
        },
        {
          label: `~5000万以下`,
          value: RevenueValues.BETWEEN_1_250K,
        },
        {
          label: `5000万~1億円`,
          value: RevenueValues.BETWEEN_250K_2M,
        },
        {
          label: `1億円~15億円`,
          value: RevenueValues.BETWEEN_2M_10M,
        },
        {
          label: `15億円~35億円`,
          value: RevenueValues.BETWEEN_10M_25M,
        },
        {
          label: `35億円~200億円`,
          value: RevenueValues.BETWEEN_25M_125M,
        },
        {
          label: `200億円以上`,
          value: RevenueValues.OVER_125M,
        },
      ];
    default:
      return [
        {
          label: t('forms:lead.revenueRanges.options.DEFAULT'),
          value: '',
        },
        {
          label: t('forms:lead.revenueRanges.options.GETTING_STARTED'),
          value: RevenueValues.GETTING_STARTED,
        },
        {
          label: `${currencyFormat(1)} - ${currencyFormat(250000)}`,
          value: RevenueValues.BETWEEN_1_250K,
        },
        {
          label: `${currencyFormat(250000)} - ${currencyFormat(2000000)}`,
          value: RevenueValues.BETWEEN_250K_2M,
        },
        {
          label: `${currencyFormat(2000000)} - ${currencyFormat(10000000)}`,
          value: RevenueValues.BETWEEN_2M_10M,
        },
        {
          label: `${currencyFormat(10000000)} - ${currencyFormat(25000000)}`,
          value: RevenueValues.BETWEEN_10M_25M,
        },
        {
          label: `${currencyFormat(25000000)} - ${currencyFormat(125000000)}`,
          value: RevenueValues.BETWEEN_25M_125M,
        },
        {
          label: `${currencyFormat(125000000)}+`,
          value: RevenueValues.OVER_125M,
        },
      ];
  }
}
