export enum RevenueValues {
  GETTING_STARTED = 'Just getting started',
  BETWEEN_1_250K = '$1 - $250,000',
  BETWEEN_250K_2M = '$250,000 - $2,000,000',
  BETWEEN_2M_10M = '$2,000,000 - $10,000,000',
  BETWEEN_10M_25M = '$10,000,000 - $25,000,000',
  BETWEEN_25M_125M = '$25,000,000 - $125,000,000',
  OVER_125M = '$125,000,000+',
}

export enum LocationValues {
  ONE = '1',
  TWO_TO_THREE = '2-3',
  FOUR_TO_TEN = '4-10',
  ELEVEN_TO_TWENTY = '11-20',
  TWENTY_ONE_TO_TWO_HUNDRED = '21-200',
  TWO_HUNDRED_PLUS = '200+',
  NO_LOCATIONS = 'No locations',
  OPENING_SOON = 'Opening soon',
}
